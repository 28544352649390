import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Container, Row, Col } from "react-bootstrap"

const Activities = ({data}) => (
  <Layout>
    <Container>
    <h1>School Activities</h1>
      <Row className="m-2 m-lg-0 g-4">
        {data.activities.edges.map(({ node }) => (
          <Col md={6} key={node.id} className="">
            <div className="card box-shadow-02 h-100">
              {/* <GatsbyImage image={getImage(node.frontmatter.image01)} className="card-img-top" alt={node.frontmatter.image01alt} /> */}
              <div className="p-3 d-flex flex-column h-100">
                <h2 className="card-title"><small>{node.frontmatter.title}</small></h2>
                <p className="card-text flex-grow-1">{node.excerpt}</p>
                <div className="text-end">
                  <a href={node.fields.slug} className="btn brick-red-bg">Read More                   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right ms-1" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                  </svg>
                  </a>
                </div>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  </Layout>
)

export const Head = () => <Seo title="School Activities" />

export default Activities

export const query = graphql`
  query {
    
    activities: allMarkdownRemark(
      sort: {fields: frontmatter___title, order: ASC}
      filter: {frontmatter: {featured: {eq: "no"}}, fields: {slug: {glob: "/school-activities/*"}}}
    ) {
      edges {
        node {
          excerpt(pruneLength: 250)
          frontmatter {
            title
            shortDescription
            image01alt
            image01 {
              childImageSharp {
                gatsbyImageData(
                  aspectRatio: 1.5
                  width: 550
                  blurredOptions: {width: 50, toFormat: JPG}
                  webpOptions: {quality: 30}
                  placeholder: BLURRED
                  quality: 60
                )
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
